import React from "react";

function DespositIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#8F8F8F"
        d="M6.67 5.554C7.097 6.63 8.128 7.396 9.35 7.396a2.892 2.892 0 002.888-2.888 2.897 2.897 0 00-1.827-2.681A2.841 2.841 0 007.745 0a2.892 2.892 0 00-2.888 2.888A2.85 2.85 0 006.67 5.554zm2.931.383v.369c0 .044-.03.073-.073.073h-.354c-.044 0-.074-.03-.074-.073v-.354a.768.768 0 01-.692-.663c0-.044.03-.074.074-.074h.353c.03 0 .06.015.074.06.03.102.118.19.236.19h.31c.19 0 .353-.132.368-.309a.356.356 0 00-.354-.383h-.236c-.442 0-.84-.324-.884-.766a.854.854 0 01.737-.928v-.354c0-.044.03-.073.073-.073h.354c.044 0 .074.03.074.073v.354c.353.03.648.31.692.663 0 .044-.03.074-.073.074h-.354c-.03 0-.059-.015-.074-.06-.03-.117-.118-.19-.236-.19h-.309c-.192 0-.354.132-.368.309a.356.356 0 00.353.383h.265c.501 0 .9.427.84.928-.03.398-.338.692-.722.751zM7.745.84c.648 0 1.238.31 1.606.78a2.899 2.899 0 00-2.888 2.873 2.047 2.047 0 01-.78-1.605A2.075 2.075 0 017.744.84zM12.665 9.488c-.397.147-1.03.31-1.738.471-.25.413-.707.752-1.43.884-.161.03-.353.044-.559.06-.5.014-1.09-.045-1.738-.09-.045 0-.089-.014-.133-.014a.331.331 0 01-.31-.295c-.014-.206.148-.368.34-.338.044 0 .103.014.147.014.84.074 1.635.148 2.121.045.928-.177 1.194-.737 1.12-1.209-.044-.383-.339-.751-.796-.722-.648.045-1.414 0-2.15-.058-.855-.06-1.724-.089-2.343-.015-.693.074-2.092.59-3.33 1.429-.604.412-1.281.899-1.68 1.193a.452.452 0 00-.088.649l1.798 2.327a.45.45 0 00.633.089l.663-.516a.614.614 0 01.545-.103c1.208.383 2.505.633 3.345.678 1.87.103 5.421-2.078 6.423-2.785 1.017-.692.28-2.121-.84-1.694z"
      ></path>
    </svg>
  );
}

export default DespositIcon;
