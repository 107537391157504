import React from "react";

function WidthDrwal() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="14"
      fill="none"
      viewBox="0 0 13 14"
    >
      <path
        fill="#8F8F8F"
        fillRule="evenodd"
        d="M5.535 9.605v3.906a.489.489 0 00.977 0V9.605a.489.489 0 00-.977 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#8F8F8F"
        fillRule="evenodd"
        d="M4.375 12.555l1.303 1.302c.19.19.5.19.69 0l1.302-1.302a.488.488 0 10-.69-.69l-.957.956-.957-.957a.488.488 0 10-.69.69zM12.047 3.256H0V7c0 .63.51 1.14 1.14 1.14h9.767c.629 0 1.14-.51 1.14-1.14V3.256zm-9.605 2.93h1.302a.489.489 0 000-.977H2.442a.489.489 0 000 .977zM0 2.279h12.047v-1.14c0-.63-.511-1.139-1.14-1.139H1.14C.51 0 0 .51 0 1.14v1.139z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default WidthDrwal;
